<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.feriado.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.feriado.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Feriado', 'Inserir')"
      :sem-filtro="!buscarPermissao('Feriado', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Feriado', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="buscarPermissao('Feriado', 'Deletar')"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Feriado', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"

              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import FeriadoService from '@common/services/cadastros/FeriadoService.js';
import helpers from '@/common/utils/helpers';

import Filtro from './filtro.vue';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },

  data() {
    return {
      tipoFeriado: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nivel',
            text: this.$t('modulos.feriado.listagem.headers.nivel'),
            sortable: true,
          },
          {
            value: 'unidadeFederativa.nome',
            text: this.$t(
              'modulos.feriado.listagem.headers.unidade_federativa'
            ),
            sortable: true,
          },
          {
            value: 'municipio.nome',
            text: this.$t('modulos.feriado.listagem.headers.municipio'),
            sortable: true,
          },
          {
            value: 'tipo',
            text: this.$t('modulos.feriado.listagem.headers.tipo'),
            sortable: true,
            formatter: (value) => {
              return this.tipoFeriado.find((tipo) => tipo.value === value)
                ?.text;
            },
          },
          {
            value: 'data',
            text: this.$t('modulos.feriado.listagem.headers.data'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.feriado.listagem.headers.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },

  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Feriado', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },

  mounted() {
    this.listarRegistros();

    this.buscarTipoFeriado();

    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.feriado.titulos.listagem')
    );
  },

  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },

    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      FeriadoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items.map((feriado) => {
            let { dia, mes, ano } = feriado;

            dia = dia.toString().length === 1 ? `0${dia}` : dia;
            mes = mes.toString().length === 1 ? `0${mes}` : mes;

            return {
              ...feriado,
              nivel: `${feriado.nivel}`,
              data: ano ? `${dia}/${mes}/${ano}` : `${dia}/${mes}`,
            };
          });

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        let promises = [];

        this.tabela.selecionados.forEach((registro) => {
          promises.push(FeriadoService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();

            this.toastSucesso(this.$t(`modulos.feriado.exclusao_sucesso`));

            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    abrirNovo: function () {
      this.$router.push({ name: 'feriado-novo' });
    },

    abrirEditar: function (item) {
      this.$router.push({
        name: 'feriado-editar',
        params: { id: item.id },
      });
    },

    buscarTipoFeriado: function () {
      EnumeradorService.buscar('EnumTipoFeriado').then((res) => {
        this.tipoFeriado = res;
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
