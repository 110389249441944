<template>
  <div class="row">
    <input-select
      v-model="filtro.unidadeFederativaId"
      retornar-objeto
      class="col-6"
      :label="
        $t(
          'modulos.feriado.filtro.unidade_federativa'
        )
      "
      :options="opcoes.unidadesFederativas"
      em-filtro
      @input="buscarMunicipios"
    />
    <input-select
      v-model="filtro.municipioId"
      retornar-objeto
      class="col-6"
      :label="
        $t(
          'modulos.feriado.filtro.municipio'
        )
      "
      :options="opcoes.municipios"
      :disabled="!filtro.unidadeFederativaId"
      em-filtro
    />
    <input-select
      v-model="filtro.dia"
      :label="$t('modulos.feriado.filtro.dia')"
      class="col-6"
      :disabled="!filtro.mes"
      :options="dias"
      em-filtro
    />
    <input-select
      v-model="filtro.mes"
      :label="$t('modulos.feriado.filtro.mes')"
      class="col-6"
      retornar-objeto
      :options="opcoes.mes"
      em-filtro
    />
    <input-text
      v-model="filtro.ano"
      class="col-6"
      type="number"
      :label="$t('modulos.feriado.filtro.ano')"
      em-filtro
    />
  </div>
</template>

<script>
import { DropdownModel } from '@common/models/geral/DropdownModel';

import helpers from '@common/utils/helpers';

import UtilsService from '@/common/services/UtilsService.js';

export default {
  props: {
    filtro: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      opcoes: {
        unidadesFederativas: [],
        municipios: [],
        mes: helpers.MesesEnum
      }
    };
  },

  computed: {
    dias: function () {      
      let { mes } = this.filtro;

      let days = 31;

      if (mes) {
        mes = mes.value;
  
        if (mes === 2) {
          days = 29;
        }
  
        if (
          mes === 4 ||
          mes === 6 ||
          mes === 9 ||
          mes === 11
        ) {
          days = 30;
        }
      }
      
      return [...Array(days + 1).keys()].filter(e => e);
    }
  },

  mounted () {
    this.buscarUnidadesFederativas()
  },

  methods: {
    buscarUnidadesFederativas: function () {
      UtilsService.buscarUnidadeFederativa()
        .then((response) => {
          this.opcoes.unidadesFederativas = new DropdownModel(response.data);
        });
    },

    buscarMunicipios: function () {
      UtilsService.buscarTodosMunicipios(this.filtro.unidadeFederativaId.value)
        .then((response) => {
          this.opcoes.municipios = new DropdownModel(response.data);
        });
    }
  }
};
</script>
